@import "bootstrap/scss/functions";

/************************************ Remove below code ************************************/
$black: #212529  !important;
$primary: #00bceb !important;
$blue1: #1E4471 !default;
$grey: #f2f2f2 !default;
$grey1: #dee2e6 !important;
$banner1:#F2F2F2;
$grey2: #6C757D !important;
$grey3: #58585B !default;
$grey4: #39393b !default;
$yellow: #ffcc01 !important;
$grey5: #ced4da !important;
$very-dark-grey: #495057 !default;
$dark-blue: #343A40 !default;
$grayish-blue: #ADB5BD;
$grey6: #adb5bd;
$white-color:#fff;
$black-color:#212529;

$light-gray: $grey !default;
$hr-border-color: $grey1 !default;
/************************************ Remove above code *******************************/



/************************************New Branding************************************/
// Brand colors
$sky: #00bceb;
$ocean: #1e4471;
$mindnight: #0d274d;

//Theme colors
$primary: #00bceb;
$secondary: #1e4471;
$tertiary: #0d274d;
$success: #6ABF4B;
$success-1:#6EBE4A;
$info: #64bbe3;
$danger: #e2231a;
$warningAlt: #eed202;
$warning: #fbab18;
$dark: #495057;
$light: #ced4da;
$link: #0175a2;

//Grayscale
$white: #ffffff;
$gray-100: #f8f8f8;
$gray-200: #f2f2f2;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-1000:#919191;
$black: #000000;
$black-100: #00000029;

$text-color: #4D4C4C;
$blue1: #2574A9;
$blue2: #032D60;
$blue3: #0374A9;
$blue4:#007493;
$orange-100:#FFC400;