@import "collapsibleItem";

footer {
    padding-bottom: 0;
    background-color: $mindnight;
    @include media-breakpoint-between(xs, md) {
        padding-bottom: 5%;
    }
    &.footer-bottom {
        position: absolute;
        bottom: 0px;
        width:100%
    }
    h2 {
        color: $white;
        font-size: 22px;
    }
    .footer-item {
        @include media-breakpoint-between(xs, md) {
            border-bottom: 0;
            margin: 10px;
        }

        .title {
            font-family: $font-cisco-light;
            &::after {
                position: absolute;
                top: 0;
                right: 10px;
            }
            @include media-breakpoint-between(xs, md) {
                margin-left: 15px;
                font-size: 16px;
            }
        }
    }

    .menu-footer.content {
        li {
            height: 35px;
            a {
                @include fonts(d, $font-cisco-extraLight, 18px, 20px );
                @include fonts(m, $font-cisco-extraLight, 16px, 20px );
                text-decoration: none;
                color: $white !important;
            }
        }
    }

    .related-tools {
        div {
            max-width: 50%;
            width: 100%;
        }
        @include media-breakpoint-between(xs, md) {
            div {
                max-width: 100%;
                margin: 0 0 0 15px;
                margin-bottom: -20px;
            }
        }
    }

    .support {
        div:nth-child(1) {
            max-width: 70%;
            width: 100%;
        }
        div:nth-child(2) {
            max-width: 30%;
            width: 100%;
        }

        @include media-breakpoint-between(xs, md) {
            div:nth-child(1),
            div:nth-child(2) {
                max-width: 100%;
                margin: 0 0 0 15px;
                margin-bottom: -20px;
            }
        }
    }

    .social-wrapper {
        height: 46px;
        @include media-breakpoint-between(xs, md) {
            margin-left: 10px;
        }
        h2 {
            font-size: 18px;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .social-links {
            width: auto;
            li {
                width: auto;
                margin-right: 35px;
                @include media-breakpoint-between(xs, md) {
                    margin-left:13px;
                    margin-right: 13px;
                }
                a {
                    font-size: 18px;
                    font-family: $font-cisco-light;
                }
                &:nth-child(1) {
                    margin-left: 40px;
                    @include media-breakpoint-between(xs,md) {
                        margin-left: 0;
                    }
                }
                img {
                    width: 30px;
                    height: 24px;
                }
            }
        }
    }

    .full-page-width {
        width: 100%;
        overflow: hidden;
        background-color: $mindnight;
        height: 53px;
        @include media-breakpoint-between(xs, md) {
            background-color: transparent;
            height: auto;
            margin-bottom: 0;
        }

        .center-content-container {
            margin: 0 auto 20px auto;
            padding: 0;
            color: $white;
            @include media-breakpoint-between(xs, md) {
                padding: 0 15px;
                margin: 0 auto;
            }

            .menu-footer.content {
                margin: 0;
                text-align: center;
                @include media-breakpoint-between(xs, md) {
                    margin: 10px 0 0 10px;
                    text-align: left;
                }
                li {
                    list-style: none;
                    display: inline-block;
                    margin-right: 30px;
                    @include media-breakpoint-between(xs, md) {
                        display: block;
                    }

                    a {
                        color: $white;
                        text-decoration: none;
                        font-size: 14px;
                        font-family: $font-cisco-light;
                    }
                }
            }
        }
    }
}
