@import "../ciscoBrand";
$lines-to-show: 5;
.hero {
    &.search-banner {
        display: none;
    }
}
.info-icon {
    .tooltip {
        color: $gray-900;
        font-size:14px;
        font-family:$font-cisco-regular;
    }
    
}
.noproducts {
    .product-description {
        display: none;
    }
}
.results-avilable {
    .product-description {
        @include media-breakpoint-up(md) {
            margin-top: -42px;
        }
    }
}
//PLP page header category content
.product-description {
    font-size: 14px;
    h2 {
        font-size: 18px;
        line-height: 20px;
    }
    p {
        font-size: 14px;
    }
    a {
        font-size: 14px;
    }
}
//hiding text for bottom pagination
.pagination-bottom {
    .pagination {
        .results-hits {
            display: none;
        }
    }
}
//for results count and pagination
.pagination {
    .results-hits {
        @include fonts(d, $font-cisco-regular, 14px, 19px);
        @include fonts(t, $font-cisco-regular, 14px, 19px);
        @include fonts(m, $font-cisco-regular, 12px, 14px);
        color: $gray-900;
        .result-keyword {
            @include fonts(d, $font-cisco-medium, 14px, 19px, 600);
            @include fonts(t, $font-cisco-medium, 14px, 19px, 600);
            @include fonts(m, $font-cisco-medium, 12px, 14px, 600);
        }
    }
    .results-pagination {
        @include media-breakpoint-down(md) {
            display: none;
        }
        text-align: right;
        ul {
            list-style: none;
            li {
                display: inline-block;
                vertical-align: middle;
                &.current-page {
                    a {
                        background-color: $primary;
                        color: $white;
                    }
                }
                a {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    color: $gray-900;
                    vertical-align: middle;
                    font-size: 14px;
                    &:hover {
                        text-decoration: none !important;
                    }
                    i {
                        display: inline-block;
                        vertical-align: text-bottom;
                    }
                }
                &.first-last {
                    .page-first i {
                        background: url(../images/direction-chevron-left-double.svg) no-repeat right;
                        width: 12px;
                        height: 16px;
                    }
                    .page-previous i {
                        background: url(../images/direction-chevron-left.svg) no-repeat right;
                        width: 8px;
                        height: 16px;
                    }
                    .page-next i {
                        background: url(../images/direction-chevron-right.svg) no-repeat right;
                        width: 8px;
                        height: 16px;
                    }
                    .page-last i {
                        background: url(../images/direction-chevron-right-double.svg) no-repeat right;
                        width: 12px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
#product-search-results {
    .result-count {
        display: none;
    }
}
#maincontent {
    .container {
        .results-pagination {
            li {
                [class*="page"] {
                    color: $gray-900;
                }
            }
            li.current-page {
                a {
                    color: $white !important;
                }
            }
        }
    }
}
#maincontent {
    .container {
        .product-tile {
            .pdp-link {
                a.link {
                    color: $gray-900 !important;
                    &:hover {
                        color: $primary !important;
                    }
                }
            }
            .current-page {
                a.link {
                    color: $gray-900;
                    font-size: 18px;
                }
            }
        }
    }
}
.product-tile {
    &.card {
        margin-bottom: 24px;
        border: 1px solid $gray-300;
        .tile-body {
            padding: 0px;
            margin: 0px;
            @include media-breakpoint-up(md) {
                min-height: 180px;
            }
        }
        .card-description {
            padding: 0px;
            .course-details {
                a.dae-acess-link{
                    float:right;
                    color:$link;
                }
                a.dae-acess-link:hover{
                    text-decoration: none;
                }
                padding-left: 20px;
                padding-top: 40px;
                .course-details-duration {
                    @include fonts(d, $font-cisco-regular, 12px, 16px);
                }
                &.lesspspace {
                    padding-top: 20px;
                }
                width: 90%;
                &-credits {
                    p {
                        @include fonts(d, $font-cisco-regular, 12px, 16px);
                        display: inline-block;
                    }
                    @include media-breakpoint-down(md) {
                        display: flex;
                        P {
                            display: inline-block;
                            align-items: center;
                        }
                    }
                    .info-icon {
                        background: url(../images/status-info-circle.svg) no-repeat right;
                        width: 12px;
                        height: 12px;
                        display: inline-flex;
                        margin-left: 5px;
                        top: 1px;
                    }
                }
                &-title {
                    font-size: 18px;
                    margin-bottom: 1rem;
                    .pdp-link {
                        a.link {
                            color: $gray-900 !important;
                            @include fonts(d, $font-cisco-regular, 18px, 20px);
                        }
                    }
                }
                &-desc {
                    display: -webkit-box;
                    -webkit-line-clamp: $lines-to-show;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    white-space: initial;
                    text-overflow: ellipsis;
                    @include media-breakpoint-up(sm){
                        min-height:70px;
                    }
                    font-size: 14px;
                    margin-bottom:20px;
                    overflow: hidden;
                    p {
                        margin-bottom: 0px;
                        @include fonts(d, $font-cisco-regular, 14px, 19px);
                        display: contents;
                    } 
                }
                &-icons {
                   
                    @include media-breakpoint-up(sm){
                        display: flex;
                        align-items: center;
                    }
                    div {
                        display: inline-block;
                        text-align: center;
                        vertical-align: top;
                        padding: 0px 4px;
                        .fa {
                            color: $gray-500;
                        }
                        p {
                            padding-top: 2px;
                            @include fonts(d, $font-cisco-regular, 10px, 12px);
                        }
                    }
                    span {
                        font-weight: 900;
                        font-family: "FontAwesome";
                        color: $gray-500;
                        font-size: 1.33em;
                        width: 22px;
                        height: 22px;
                        display: inline-block;
                    }
                    // self-paced Training
                    &-5 {
                        margin-bottom: -10px;
                        span {
                            background: url(../images/app-contact.svg) no-repeat right;
                            font-size: 1.33em;
                            margin-bottom:-4px;
                            height:25px;
                            width:25px;
                            background-size: 22px;
                        }
                        p {
                            width: 54px;
                        }
                    }
                    // Video Training
                    &-3 {
                        margin-bottom: -10px;
                        span {
                            background: url(../images/video-aux-camera_alignbottom.svg) no-repeat right;
                            margin-top: -1px;
                            width: 30px;
                            height: 20px;
                            margin-bottom:-3px;
                        }
                        p {
                            width: 50px;
                        }
                    }
                    // Labs
                    &-28 {
                        span:before {
                            content: "\f0c3";
                            font-size: 1.33em;
                        }
                    }
                    // Pracrice questions
                    &-23 {
                        margin-bottom: -10px;
                        span:before {
                            content: "\f15b";
                            font-size: 1.33em;
                        }
                        p {
                            width: 50px;
                        }
                    }
                    // Game
                    &-29 {
                        span:before {
                            content: "\f11b";
                            font-size: 1.33em;
                        }
                        p {
                            width: 50px;
                            padding-left:8px;
                        }  
                    }
                    // Mentor Guide
                    &-27 {
                        margin-bottom: -15px;
                        span{
                            margin-top:-5px;
                        }
                        span:before {
                            content: "\f0c0";
                            font-size: 1.33em;
                        }
                        p {
                            width: 50px;
                            padding-left:10px;
                        }
                    }
                    // practce Exam
                    &-26 {
                        margin-bottom: -8px;
                        span {
                            background: url(../images/app-compliance.svg) no-repeat right;
                            font-size: 1.33em;
                            margin-bottom:-4px;
                            height:25px;
                            width:25px;
                        }
                        p {
                            width: 50px;
                        }
                    }
                    // Reference Library
                    &-24 {
                        margin-bottom: -10px;
                        span:before {
                            content: "\f02d";
                            font-size: 1.33em;
                        }
                        p {
                            width: 50px;
                        }
                    }
                }
            }
        }
        .certification-tag {
            padding: 6px 10px;
            background: $orange-100;
            color: $gray-900;
            position: absolute;
            width: 100%;
            height: 24px;
            margin-top: -0.28px;
            line-height: normal;
            p {
                @include fonts(d, $font-cisco-regular, 12px, 16px);
            }
            .badge-inner-shape {
                position: absolute;
                width: 18px;
                height: 18px;
                border-bottom: solid 8px $warningAlt;
                border-left: solid 8px transparent;
                border-right: solid 8px transparent;
                right: 10px;
                bottom: 0px;
            }
            .arrow-inner {
                bottom: -4px;
                right: 14px;
                width: 11.5px;
                position: absolute;
                border-style: solid;
                border-color: $white;
                border-width: 0px 1px 1px 0px;
                transform: rotate(-135deg);
                height: 11px;
            }
            .certificate-badge {
                content: "";
                bottom: 0px;
                position: absolute;
                right: 10px;
                width: 18px;
                height: 100%;
                background: $primary;
                border: 1px solid $white;
            }
        }
        .card-price-details {
            background-color: $gray-200;
            @include media-breakpoint-up(md) {
                min-height: 220px;
            }
            &-inner {
                position: absolute;
                bottom: 20px;
                left: 0px;
                right: 0px;
                a {
                    &.free-trail {
                        display: inline-block;
                        margin-top: 10px;
                        color: $link !important;
                        @include fonts(d, $font-cisco-regular, 18px, 26px);
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    &.strike-through {
                        margin-right: 0px;
                        margin-left: 0px;
                    }
                    &.sales {
                        font-size: 16px;
                    }
                }
                @include media-breakpoint-down(md) {
                    position: static;
                    align-items: flex-end;
                    .sales {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
                .cart-and-ipay {
                    @include media-breakpoint-down(md) {
                        padding-bottom: 0px;
                    }
                }
            }
            .price {
                text-align: center;
            }
            .cart-and-ipay {
                padding-top: 24px;
                @include media-breakpoint-down(md) {
                    padding-top: 10px;
                }
                .col-sm-12 {
                    @include media-breakpoint-down(md) {
                        justify-content: center;
                        display: flex;
                    }
                }
                .cui-buy-button {
                    max-width: 140px;
                    @include fonts(d, $font-cisco-regular, 16px, 22px);
                }
                .cui-buy-button.ilt {
                    font-size: 14px;
                    @include media-breakpoint-down(md) {
                        line-height: normal;
                    }
                }
                .cui-secondary-button {
                    max-width: 140px;
                    @include fonts(d, $font-cisco-regular, 16px, 22px);
                    border: 1px solid $link;
                    padding: 7px 21px;
                }
            }
        }
    }
}

.search-results {
    .product-tile {
        &.card {
            .tile-body {
                @include media-breakpoint-up(md) {
                    min-height: 240px;
                }
            }
            .card-price-details {
                @include media-breakpoint-up(md) {
                    min-height: 240px;
                }
            }
        }
    }
}

//no results page overriding
.search-tips {
    ul {
        li {
            list-style: none;
            margin: 3px 0px;
            &:before {
                content: "\f0da";
                font-size: 1.1em;
                font-family: "FontAwesome";
                color: $gray-800;
                width: 14px;
                height: 6px;
                display: inline-block;
            }
        }
    }
}
