@import "../variables";
@import "../ciscoBrand";

:root{

    /************************************ Remove below code ************************************/
    --color-black: #{$black};
    --color-primary: #{$primary};
    --color-blue1: #{$blue1};
    --color-grey1: #{$grey};
    --color-grey2: #{$grey2};
    --color-grey3: #{$banner1};
    --skin-primary-color-1: var(--color-primary) !important;
    --skin-primary-color-2: var(--color-blue1) !important;
    --skin-banner-background-color-1:var(--color-grey3) !important;
    --skin-primary-color-black:var(--color-black) !important;
    /************************************ Remove above code ************************************/

    --skin-header-font: 'CiscoSansRegular' !important;
    --skin-body-font: 'CiscoSansRegular' !important;
}