@import "../variables";
	//custom scroll bar for user agreement section
	.scrollbar {
		// margin-left: 30px;
		float: left;
		height: 300px;
		width: 100%;
		background: #f5f5f5;
		overflow-y: scroll;
		overflow-x: hidden;
		// margin-bottom: 25px;
	}

	.force-overflow {
		min-height: 100px;
	}

	#scroll::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}

	#scroll::-webkit-scrollbar {
		width: 5px;
		background-color: #f5f5f5;
	}

	#scroll::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: $black-color;
	}

