.site-search {
    margin: 0 auto;
    width: 952px;
    @include media-breakpoint-down(sm) {
        width: 90%;
    }
    @include media-breakpoint-down(md) {
        width: 90%;
    }

    input {
        border-radius: 20px;
        padding-left: 2.1rem;
        font-size: 22px;
        height: 45px;
        font-family: $font-cisco-light;
        @include media-breakpoint-down(xs) {
            font-size: 14px;
            height: 35px;
        }
        &:focus{
            border-color: transparent;
            box-shadow: none;
        }
    }

    input.form-control.is-invalid {
        border-color: transparent;
        box-shadow: none;
        &:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }
    .search_btn {
        position: absolute;
        right: 0.5rem;
        top: 0.3rem;
        cursor: pointer;
        pointer-events: none;
        width: 35px;
        height: 35px;
        border: none;
        border-radius: 50%;
        background-color: $primary;
        @include media-breakpoint-down(xs) {
            top: 0.2rem;
            width: 28px;
            height: 28px;
        }
    }
    .search_btn:focus {
        outline: none;
    }
    .reset-button {
        top: 1em;
        right: 4em;
    }
}
.search {
    display: none;
    position: absolute;
    z-index: 2;
    background-color: $tertiary;
    padding: 50px 0;
    width: 100%;
    text-align: center;
    @include media-breakpoint-down(xs) {
        padding: 25px 5px;
    }
}
.search .up-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid $secondary;
    display: block;
    position: absolute;
    top: -15px;
    right: 24%;
    color: $secondary;
    @include media-breakpoint-down(xs) {
        top: -15px;
        right: 16%;
    }

    @include media-breakpoint-between(sm, md) {
        right: 11%;
    }
}
#search-results li {
    text-align: left;
}
.search_shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: $mindnight;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s ease-in-out 400ms, opacity 400ms;
}
.shadowIn {
    opacity: var(--lwc-opacity5, 0.5);
    visibility: visible;
    transition: visibility 0s ease-in-out 0s, opacity 400ms;
}

.suggestions .category-parent,
.header {
    font-size: 16px;
    font-family: $font-cisco-medium;
    color: $dark;
}
.suggestions .items {
    font-size: 16px;
    font-family: $font-cisco-light;
    color: $dark;
}

.suggestions .header {
    color: $dark;
    font-size: 16px;
    font-family: $font-cisco-medium;
}
.suggestions {
    width: 95%;
    left: 2.5%;
    top: -1px;
    border-top: none;
    @include media-breakpoint-down(xs) {
        position: absolute;
        top: 25px;
    }
    @include media-breakpoint-down(sm) {
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        left: 50%;
    }
    @include media-breakpoint-between(sm, md) {
        top: 40px;
    }
}
.suggestions .category-parent {
    @include media-breakpoint-down(sm) {
        padding-bottom: 5px;
    }
}
.suggestions a {
    color: $dark;
}
.suggestions .item .name {
    white-space: normal;
}
.highlight {
    font-weight: bold;
}

