.info-icon {
    .tooltip {
        min-width: 18rem;
    }
}
.tooltip {
    background-color: $white !important;
    border: 1px solid $primary;
    color: $black;
}
.info-icon .tooltip::after {
    border-top: solid #00bceb 0.625em !important;
}
