.cart-page {
    .coupons-and-promos {
        .coupon-price-adjustment {
            border: none;
            span {
                font-size: 14px;
                font-family: $font-cisco-regular;
                &.coupon-applied {
                    color: $gray-900;
                }
            }
        }
        .coupon-code {
            span {
                &.small {
                    font-size: 12px;
                    color: $gray-700;
                }
            }
            button {
                &.delete-coupon-confirmation-btn {
                    color: $gray-600;
                    font-size: 12px;
                    background: transparent;
                    border: none;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .coupon-promotion-relationship {
        	display: none;
        }
    }
    .totals {
        h2 {
            font-family: $font-cisco-medium !important;
            font-size: 18px !important;
            line-height: 25px !important;
        }
        .checkout-continue {
            a {
                &.cui-buy-button {
                    @include media-breakpoint-down(lg) {
                        font-size: 14px !important;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 16px !important;
                    }
                }
            }
        }
        .promo-code-form {
            display: block;
            .promo-code-btn {
                height: 30px;
            }
        }
        .promo-code-submit {
            .cui-secondary-button {
                width: 70px;
                min-width: 70px;
                padding: 0px;
                font-size: 12px;
                @include media-breakpoint-down(md) {
                    min-width: auto;
                }
            }
        }
        .sub-total {
            font-weight: normal !important;
        }
        .coupon-code-field {
            font-size: 14px;
            height: 30px;
            ~ .coupon-error {
                display: none;
            }
            &.is-invalid {
                & ~ div {
                    &.coupon-error {
                        display: block;
                        color: $danger;
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                max-width: 200px;
            }
        }
        .promotion-information {
        	.applied-promotion-discount {
        		float: right;
        	}
        }

        .total-before-tax{
            color: $gray-900;
            font-weight: bold;
        }
    }
    .server-down-msg {
   		color: $danger;
    	position: relative;
    	top: -5px;
    	left: 15px;
    }
    .line-item-total-price{
        .pricing {
            color: $gray-900;
            font-family: $font-cisco-regular;
        }
    }
}

