// Product Carousels
.homepage-product-listing {
  @include media-breakpoint-up(xl) {
    width: 1020px;
    margin: 0 auto;
  }
  .prod-item {
    border-radius: 4px;
    width: 280px;
    margin: 0 10px;
    outline: 0;
    .product-tile {
      border: 1px solid $gray-400;
      position: relative;
      width: 280px;
      border-radius: 4px;
      box-shadow: 0px 3px 12px $gray-400;
      margin-bottom: 1px;
      @include media-breakpoint-down(md) {
        width: auto;
      }
      .certification-tag {
        padding: 3px 10px;
        background: $yellow;
        color: $black;
        position: absolute;
        width: 100%;
        height: 24px;
        @include fonts(d, $font-cisco-regular, 14px, 20px);
        .outer-badge {
          .certificate-badge {
            content: "";
            bottom: 0px;
            position: absolute;
            right: 10px;
            width: 16px;
            height: 100%;
            background: $primary;
            border: 1px solid $white;
          }
        }
        .certification-text {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 15px;
        }
        .badge-inner-shape {
          position: absolute;
          width: 16px;
          height: 18px;
          border-bottom: solid 8px $yellow;
          border-left: solid 8px transparent;
          border-right: solid 8px transparent;
          right: 10px;
          bottom: 0px;
        }
        .arrow-inner {
          bottom: -4px;
          right: 14px;
          width: 10px;
          position: absolute;
          border-style: solid;
          border-color: $white-color;
          border-width: 0px 1px 1px 0px;
          transform: rotate(-135deg);
          height: 11px;
        }
      }
      .image-container {
        position: relative;
        z-index: -1;
        text-align: center;
        height: 140px;
        overflow: hidden;
        border-radius: 1px;
        img {
          display: block;
          min-height: 140px;
          width: 100%;
          line-height: 25px;
        }
      }
      .tile-body {
        padding: 20px;
        height: 260px;
        min-height: 260px;
        position: relative;
        .pdp-link {
          position: relative;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-height: 16px;
          min-height: 56px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          a {
            color: $black;
            @include fonts(d, $font-cisco-regular, 14px, 16px);
            @include fonts(t, $font-cisco-regular, 16px, 16px);
            @include fonts(m, $font-cisco-regular, 16px, 16px);
          }
        }
        .card-price-details {
          position: absolute;
          width: 90%;
          bottom: 25px;
          &-inner {
            height: 100%;
            button {
              max-width: 140px;
            }
          }
          a {
            &.free-trail {
              min-width: 140px;
              display: block;
              height: 12px;
              &.home-carousal {
                margin-bottom: 10px;
              }
            }
          }
          @include media-breakpoint-down(md) {
            width: 100%;
            left: 18px;
            .cart-and-ipay {
              text-align: center;
            }
          }
        }
        .price {
          @include fonts(d, $font-cisco-bold, 14px, 20px);
          margin-bottom: 0px;
          display: inline-block;
          float: left;
          color: $gray-900;
          span {
            display: block;
            &.sales {
              padding-top: 5px;
            }
          }
        }
        .cart-and-ipay {
          text-align: right;
          padding-bottom: 0px;
          .col-sm-12 {
            padding: 0;
          }
          button,
          a {
            @include fonts(d, $font-cisco-regular, 16px, 22px);
          }
          .info-icon {
            .tooltip {
              margin-left: -40px;
              @include media-breakpoint-down(lg) {
                margin-left: -68px;
              }
            }
          }
          .cui-secondary-button {
            margin-bottom: 10px;
            margin-top: 8px;
            max-width: 140px;
            padding: 7px 15px;
            border: 1px solid $link;
          }
          @include media-breakpoint-down(md) {
            .cui-buy-button {
              display: inline-block;
              max-width: 140px;
              text-align: center;
            }
            .cui-secondary-button {
              display: inline-block;
              max-width: 140px;
              text-align: center;
            }
            .free-trail {
              float: right;
            }
          }
        }
      }
    }
  }

  .slick-prev {
    left: 10px;
    top: 41%;
    z-index: 1;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 55%;
    @include media-breakpoint-between(lg, xl) {
      left: -5px;
    }
    @include media-breakpoint-up(xl) {
      left: -20px;
      top: 42%;
    }
    &:hover {
      background-color: $primary;
      width: 38px;
      height: 38px;
    }
  }
  .slick-next {
    right: 10px;
    top: 41%;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 55%;
    @include media-breakpoint-between(lg, xl) {
      right: -20px;
    }
    @include media-breakpoint-up(xl) {
      right: -12px;
      top: 42%;
    }
    &:hover {
      background-color: $primary;
      width: 38px;
      height: 38px;
    }
  }

  .slick-prev:before {
    content: "\f053";
    color: $primary;
    font-size: 26px;
    font-family: "Font Awesome\ 5 Free";
    font-weight: 900;
  }

  .slick-next:before {
    content: "\f054";
    font-size: 26px;
    color: $primary;
    font-family: "Font Awesome\ 5 Free";
    font-weight: 900;
  }

  .slick-next:hover::before,
  .slick-prev:hover::before {
    color: $white !important;
  }

  .slick-dots {
    bottom: 0;
    @include media-breakpoint-down(lg) {
      bottom: -25px;
    }
    li {
      margin: 0;
      button::before {
        font-size: 22px;
        color: $grey5;
        opacity: 1;
      }
      &.slick-active {
        button::before {
          color: $primary;
          opacity: 1;
        }
      }
    }
  }
}

// Carousel heading
.home-product-tiles,
.cart-recomendations,
.pdp-recommendations {
  margin: 10px auto 30px;
  @include media-breakpoint-down(lg) {
    margin: 10px auto 50px;
  }
  h2 {
    @include fonts(d, $font-cisco-medium, 22px, 30px);
    @include fonts(t, $font-cisco-medium, 20px, 24px);
    @include fonts(m, $font-cisco-medium, 18px, 18px);
    color: $gray-900;
    margin: 0 0 15px;
  }
}
.cart-recomendations {
  padding-left: 0;
  @include media-breakpoint-down(lg) {
    padding-left: 15px;
  }
}
header {
  ~ #maincontent {
    .container {
      &.product-detail.product-wrapper,
      &.cart-recomendations {
        .pdp-link {
          a {
            color: $black !important;
            &:hover {
              color: $primary !important;
            }
          }
        }
        .course-details-credits {
          position: relative;
          .info-icon {
            background: url(../images/status-info-circle.svg) no-repeat right;
            width: 13px;
            height: 13px;
            display: inline-flex;
            top: -1px;
            vertical-align: middle;
            position: relative;
            .tooltip {
              color: $black;
              min-width: 14rem;
              cursor: auto;
            }
          }
          p {
            display: inline-block;
            @include fonts(d, $font-cisco-regular, 12px, 16px);
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .product-detail.product-wrapper {
    .homepage-product-listing {
      .prod-item {
        width: auto;
        margin: 15px 0;
      }
    }
  }
}
  
.homepage .bottom-callout{
  margin-top: 40px;
}

.homepage-product-listing .info-icon .tooltip::after {
  left:83% !important;
  }
