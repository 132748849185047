@import "fonts";
@import "variables";

.consent-modal {
	position: static;
  .modal-dialog {
    bottom: 0;
    position: fixed;
    top: auto;
    right: auto;
    left: auto; 
    padding: 10px;
    margin: 0 auto;
    width: 96%;
    z-index: 1000;
    @media (min-width: 768px) and (max-width: 1023px) {
    	right: 5%;
		margin-top: 130px;
		z-index: 1000;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
    	right: 5%;
    	width: 380px;
		z-index: 1000;
    }
    @include media-breakpoint-up(xl) {	
    	right: 6%;
    	width: 380px;
    }
    .modal-content {
        border-radius: .625rem;
        box-shadow: 0px 1px 3px #00000029;
        -webkit-box-shadow: 0px 1px 3px #00000029;
        -moz-box-shadow: 0px 1px 3px #00000029;
         @include media-breakpoint-up(md) { 
        	//border-radius: 0;
    	 	border: 0;
    	 }
        .modal-header {
        	font-size: 14px;
    		font-family: $font-cisco-medium;
    		color: $gray-600;
    		border: 0;
			font-weight:600;
    		@include media-breakpoint-up(md) { 
    		  color: $gray-900;
    		  background-size: 3%; 
    		}
            .close-consent {
              border: 0;
    		  background: $white;
    		  padding: 0;
    		  outline: none;
          	  span {
          		background: url(../images/app-close.svg) no-repeat right;
    			width: 20px;
    			height: 20px;
    			display: flex;
          	  }
            }
        }
        .modal-body {
          padding-top: 0;
        	.tracking-consent {
				text-decoration:none;
        		p {
        	  	  font-family:$font-cisco-regular;
        	  	  color: $gray-600;
        	  	  font-size: 12px;
        	  	  margin-bottom: 0;
        	  	  @include media-breakpoint-up(md) { 
        	  	  	font-size: 14px; 
        	  	  	color: $gray-900;
        	  	  }
        	  	  a {
    				color: $link;
        	  	  }
        		}
        	}
        }
        .modal-footer {
            display: none
        }
    }     
  }
}

.modal-open {
	overflow: auto;
	padding-right: 0px !important;
}