@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            .title {
                line-height: 2.5rem; /* 40/16 */

                @include clearfix;

                &::after {
                    float: right;
                    content: "\F107";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 600;
                    font-size: 30px;
                    float: right;
                    color: #919191;
                    -webkit-text-stroke: 2.5px #ffffff;
                }
            }

            .content,
            .card-body {
                display: none;
            }

            &.active {
                .title::after {
                    margin-top: -0.125em; /* 2/16 */
                    content: "\F106";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 600;
                    font-size: 30px;
                    float: right;
                    color: #919191;
                    -webkit-text-stroke: 2.5px #ffffff;
                }

                .content,
                .card-body {
                    display: block;
                }
            }
        }
    }

    .container div.collapsible-#{$size} button.title {
        color: $black;
        text-decoration: none;
        border: none;
        background-color: transparent;

        &:hover {
            text-decoration: none;
        }
    }
}
