@import "../fonts";

//policy and faq styles

.policy-section {
    .toggle{
        font-size: 20px;
        padding: 10px 0px;
        color:$dark;
        cursor:pointer;
        .down-arrow{
            width:30px;
            height:30px;
            float:right
        }
    }
    .left-section{
        border-right: 1px solid $gray-1000;
        @include media-breakpoint-down(sm) {
           border-right:none;
        }
        #left-tab-policy{
            padding-left:0px;
            display:block;
            
            li{
                font-size:14px;
                font-family: $font-cisco-regular;
                padding:10px 0px;
                text-decoration:none;
                list-style:none;
               a:hover{
                text-decoration:none;
               }
            }
        }
    }
    .right-section{
        #right-tab-policy{
            padding-left:0px;
            display:block;
            li{
                display:none;
                padding:15px 0px;
                font-family: $font-cisco-light ;
                font-size:16px;
            }
            li.active{
               display:block; 

            }
        }
    }
      
}
header ~ #maincontent .container #left-tab-policy li a{
    color: $dark !important;
    font-size:14px;
    font-family: $font-cisco-regular;
  }
header ~ #maincontent .container #left-tab-policy li.active a{
    color:$link !important;
    font-weight:bold!important;
  }
.rotate_faq{
    transform: rotate(-180deg);
}
